@font-face {
  font-family: gotham-light;
  src: local("GothamLight"), url("font/GothamLight.ttf") format('ttf');
}

@font-face {
  font-family: gotham-medium;
  src: local("GothamMedium"), url("font/GothamMedium.ttf") format('ttf');
}

body {
  margin: 0;
  font-family: gotham-light, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  font-size: 11pt !important;
  letter-spacing: 1pt;
  color: #4a6282 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f2 !important;
}

h1, h2, h3, h4, h5, h6 {
  color: #4a6282 !important;
}

.ant-table {
  color: #4a6282 !important;
}

.ant-select, .ant-picker-input > input, .ant-input, .ant-input-number, .ant-form-item, .ant-descriptions-item-content {
  color: #95a1b4 !important;
}

.ant-form-item-label > label, .ant-checkbox-wrapper, .ant-result-title, .ant-modal-title, .ant-descriptions-item-label, .ant-layout-header {
  color: #4a6282 !important;
}

.ant-input, .ant-select-selector {
  border-radius: 0 !important;
}

.ant-spin-dot-item:nth-child(odd) {
  background-color: #ec008c !important;
}
.ant-spin-dot-item:nth-child(even) {
  background-color: #003357 !important;
}

.ant-checkbox-input, ::selection {
  background: #e4028d !important;
}

.ant-checkbox-checked::after {
  border: 1px solid #e4028d !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #e4028d !important;
  border-color: #e4028d !important;
}

.ant-checkbox-wrapper:hover, .ant-pagination-item-active, .ant-pagination-item:focus, .ant-pagination-item:active, .ant-pagination-item:hover, .ant-checkbox-inner, .ant-checkbox:hover, .ant-checkbox-inner, .ant-checkbox-input:focus, .ant-checkbox-inner {
  border-color: #e4028d !important;
  a {
    color: #e4028d !important;
  }
}

.ant-switch-checked {
  background-color: #e4028d !important;
}

.ant-input-affix-wrapper:hover {
  border-color: #4a6282 !important;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #003357 !important;
}

.ant-input-affix-wrapper, .ant-dropdown-menu {
  border-radius: 0 !important;
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
  font-size: 11pt !important;
}

.ant-pagination-item-active a, .ant-pagination-item a:focus, .ant-pagination-item a:hover, .ant-pagination-item a:active, .ant-pagination-item {
  color: #e4028d !important;
}

.ant-dropdown-menu-item-selected {
  color: #4a6282 !important;
  background-color: #d1e2f7 !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #e9f0f7 !important;
}

/*.ant-btn:hover, .ant-btn:focus {*/
/*  color: #4a6282 !important;*/
/*  border-color: #4a6282 !important;*/
/*}*/
