.container {
    text-align: center;
    position: absolute;
    //margin-top: 9%;
    top: calc(50% - 280px);
    left: 0;
    right: 0;
}

.wrap {
    border: 1px solid #9facbc;
    background-color: white;
    width: 400px;
    margin-right: 0;
    margin-left: 0;
    display: inline-block;
    padding-top: 30px;
    padding-bottom: 40px;
}

.p {
    text-align: left;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.title {
    font-size: 25px;
    font-weight: lighter;
    margin-top: -15px;
}


.content {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.inputs {
    border: 1px solid #9facbc;
}

.verifyBtn {
    //width: 49%;
    width: 100%;
    margin-bottom: 25px;
}


.leftButton {
    width: 49%;
    margin-top: 10px;
    margin-left: 1%;
}


.rightButton {
    width: 49%;
    margin-top: 10px;
    margin-right: 1%;
}


.getCaptchaButton {
    width: 49%;
}

.resultTitle {
    font-size: 1rem;
    line-height: 2.1rem;
}

.resultIcon {
    width: 45%;
}


